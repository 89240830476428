<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Jenis Pinjaman</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data Jenis Pinjaman</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Jenis Pinjaman"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="kode"
            header="kode"
            filterField="kode"
            style="min-width: 10rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="nama"
            header="nama simpanan"
            style="min-width: 15rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>

          <Column
            field="min"
            header="min"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.min) }}
            </template>
          </Column>
          <Column
            field="max"
            header="max"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.max) }}
            </template>
          </Column>
          <Column
            field="status"
            header="Status"
            style="min-width: 12rem"
            header-style="justify-content: center"
            sortable
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <div style="width: 100%; text-align: center">
                <Tag
                  v-if="slotProps.data.status == 'active'"
                  severity="success"
                  value="active"
                ></Tag>
                <Tag
                  v-else
                  :style="{ background: 'red' }"
                  value="inactive"
                ></Tag>
              </div>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="list_status"
                optionValue="value"
                optionLabel="label"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit item' : 'Tambah item'"
      v-model:visible="dialog"
      class="p-overflow-hidden"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      maximizable
    >
      <form-jpin
        :item="form"
        :loading="isLoadingSave"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Item"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ form.name }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItem"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import JpinService from '@/services/JpinService'
import FormJpin from '@/components/master/FormJpin'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import { formatCurrency } from '@/helpers/index'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Jpin',
  components: {
    FormJpin,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      jpinService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      list_status: [
        { value: 'active', label: 'Aktif' },
        { value: 'inactive', label: 'Tidak Aktif' },
      ],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'kode',
        sortOrder: 'asc',
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    }
  },
  created() {
    this.jpinService = new JpinService()
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val)
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.jpinService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Jenis Pinjaman', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.form = { ...this.form }
      this.dialog = true
    },
    onAddData() {
      this.form = {
        id: 0,
        nama: null,
        min: 0,
        max: 0,
        status: 'active',
      }
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      if (data.id === 0) {
        this.jpinService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Jenis Pinjaman',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Jenis Pinjaman', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.jpinService
          .update(data.id, data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Jenis Pinjaman',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Jenis Pinjaman', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteItem() {
      this.jpinService
        .delete(this.form.id)
        .then((res) => {
          if (res.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Jenis Pinjaman',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Jenis Pinjaman', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
